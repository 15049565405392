<template>
  <!-- begin::Example -->
  <div>
    <pre class="decode-result" v-if="showDataDebug">
      Last result debug : <b>{{ result }}</b>
    </pre>

    <qrcode-stream :camera="camera" :track="paintBoundingBox" @decode="onDecode" @init="onInit" class="vh-100 vw-100 bg-light stream">
      <div v-show="!showScanConfirmation" class="scan-decode">
        <img :src="getPath('media/opalean/svg/Qrcode.svg')" alt="Scan currently ongoing mark" width="50%" />
      </div>
      <div v-show="showScanConfirmation" class="scan-confirmation">
        <img :src="getPath('media/opalean/svg/Checkmark.svg')" alt="Scan confirmation checkmark" width="30%" />
      </div>
      <div class="camera-buttons">
        <button @click="switchCamera" class="switch-camera">
          <img :src="getPath('media/opalean/svg/Camera_switch.svg')" alt="Switch camera icon" />
          <label class="ml-1">
            <span class="label label-inline">{{ camera }}</span>
          </label>
        </button>
      </div>
      <p class="camera-error alert alert-danger" role="alert" v-if="noFrontCamera">
        <translate>You don't seem to have a front camera on your device</translate>
      </p>
      <p class="camera-error alert alert-danger" role="alert" v-if="noRearCamera">
        <translate>You don't seem to have a rear camera on your device</translate>
      </p>
    </qrcode-stream>
  </div>
  <!-- end::Example -->
</template>

<style scoped>
.stream {
  height: calc(100vh - 140px) !important; /* = Header + boutons */
}

.scan-decode {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from "@/core/services/store/htmlclass.module.js";
import statics from "@/core/statics/statics.js";

export default {
  name: "Scan",
  components: {
    QrcodeStream,
  },
  data() {
    return {
      camera: "auto",
      result: null,
      showScanConfirmation: false,

      // Cameras
      noRearCamera: false,
      noFrontCamera: false,

      //Preferences
      showDataDebug: statics.showDataDebug,
    };
  },
  beforeMount() {
    // Add body class
    this.$store.dispatch(ADD_BODY_CLASSNAME, "fitted-content");
  },
  destroyed() {
    // Remove body class
    this.$store.dispatch(REMOVE_BODY_CLASSNAME, "fitted-content");
  },
  methods: {
    // Qrcode functions

    async onInit(promise) {
      this.noRearCamera = false;
      this.noFrontCamera = false;

      try {
        await promise;
      } catch (error) {
        // Switch cameras
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      } finally {
        this.showScanConfirmation = this.camera === "off";
      }
    },

    async onDecode(content) {
      this.result = content;
      this.OperationID = content.substring(content.lastIndexOf("/") + 1);

      this.pause();
      await this.timeout(500);
      console.log("Redirect to operation = " + this.OperationID);
      this.$router.push({
        name: "route.operations.edit",
        params: {
          OperationID: this.OperationID,
        },
      });
      this.unpause();
    },

    // Used to switch between cameras
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
        case "auto":
          this.camera = "rear";
          break;
      }
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },

    // Used to paint a rect when codes are detected
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 4;
        ctx.strokeStyle = "#25ae99";
        ctx.strokeRect(x, y, width, height);
      }
    },

    getPath(url) {
      return process.env.BASE_URL + url;
    },
  },
};
</script>
